import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { FiCheckCircle } from "react-icons/fi";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

export const MegaMenu = ({ categories }) => {
  const imag_url = "https://nasksoft.com/nasksoft/public/";
  console.log(categories);
  const parms=useParams()
  const [show,setShow]=useState('')
  return (

      <div className="row mega_menue">
        {categories?.map((item,i)=>{
          return(
            <div className="col-3">
              <div className="d-flex justify-content-between align-items-center">
                <Link  to={`/service/${item?.id}`} style={{textDecoration:"none" ,color:"black"}}>
              <h6 style={{fontWeight:"bold",marginTop:"10px"}}>{item?.name}</h6>
                </Link>
            {show===i?<IoIosArrowUp   onClick={()=>{
              if(show===i){
                setShow("")
              }
              else{
                setShow(i)
              }
            }} style={{marginRight:"10px",color:"#6BABE9"}} />:<IoIosArrowDown  onClick={()=>{
              if(show===i){
                setShow("")
              }
              else{
                setShow(i)
              }
            }} style={{marginRight:"10px",color:"#6BABE9"}} />} 
              </div>

              


             {show===i && item?.services?.map((items)=>{
              return(
                <div className="d-flex">
                  <FiCheckCircle style={{marginRight:"10px",color:"#6BABE9"}} />
                  <Link style={{textDecoration:"none" ,color:`${parms?.id==items.id?"black":"gray"}`}} to={`/child-service/${items?.id}`}>
                  <h6 style={{fontWeight:"lighter",fontSize:"15px"}}>{items?.name}</h6>
                  </Link>
                </div>
              )
             })}   
           
          </div>
          )
        })}

      </div>

  );
};
 