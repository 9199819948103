import React, { useEffect } from 'react'
// import img1 from '../Images/cas3 (1).png'
// import img2 from '../Images/cas3 (2).png'
import { useData } from '../Context/Context_Provider'

function Header() {
  const { caseBanner, getcasebanner, imag_url } = useData()
  useEffect(() => {
    getcasebanner()
  }, [])
  console.log(caseBanner);
  return (
    <>
      <div className='caseStudy-header'>
        <div className='container-fluid p-0'>
          <div className='header-bg'>
            <img src={'https://fleep.io/blog/wp-content/uploads/2018/08/aerial-background-beverage-1038674-e1535098537994.jpg'} className='casemask2' />
            <div className='header-heading text-center mx-auto'>
              <h1>Case Study</h1>
              <h4>NaskSoft</h4>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Header
